//      

const {parseCSSColor} = require('csscolorparser');

/**
 * An RGBA color value. All components are in the range [0, 1] and R, B, and G are premultiplied by A.
 * @private
 */
class Color {
              
              
              
              

    constructor(r        , g        , b        , a         = 1) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

                        
                        
                              

    static parse(input         )               {
        if (!input) {
            return undefined;
        }

        if (input instanceof Color) {
            return input;
        }

        if (typeof input !== 'string') {
            return undefined;
        }

        const rgba = parseCSSColor(input);
        if (!rgba) {
            return undefined;
        }

        return new Color(
            rgba[0] / 255 * rgba[3],
            rgba[1] / 255 * rgba[3],
            rgba[2] / 255 * rgba[3],
            rgba[3]
        );
    }
}

Color.black = new Color(0, 0, 0, 1);
Color.white = new Color(1, 1, 1, 1);
Color.transparent = new Color(0, 0, 0, 0);

module.exports = Color;
