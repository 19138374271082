//      

const assert = require('assert');

                                                
                                                     
                                                           
                                     

class Coalesce                       {
               
                            

    constructor(type      , args                   ) {
        this.type = type;
        this.args = args;
    }

    static parse(args              , context                ) {
        if (args.length < 2) {
            return context.error("Expectected at least one argument.");
        }
        let outputType       = (null     );
        if (context.expectedType && context.expectedType.kind !== 'value') {
            outputType = context.expectedType;
        }
        const parsedArgs = [];
        for (const arg of args.slice(1)) {
            const parsed = context.parse(arg, 1 + parsedArgs.length, outputType);
            if (!parsed) return null;
            outputType = outputType || parsed.type;
            parsedArgs.push(parsed);
        }
        assert(outputType);
        return new Coalesce((outputType     ), parsedArgs);
    }

    evaluate(ctx                   ) {
        let result = null;
        for (const arg of this.args) {
            result = arg.evaluate(ctx);
            if (result !== null) break;
        }
        return result;
    }

    eachChild(fn                      ) {
        this.args.forEach(fn);
    }
}

module.exports = Coalesce;
