//      

const Point = require('@mapbox/point-geometry');

const StyleLayer = require('../style_layer');
const LineBucket = require('../../data/bucket/line_bucket');
const {multiPolygonIntersectsBufferedMultiLine} = require('../../util/intersection_tests');
const {getMaximumPaintValue, translateDistance, translate} = require('../query_utils');
const properties = require('./line_style_layer_properties');

const {
    Transitionable,
    Transitioning,
    Layout,
    PossiblyEvaluated,
    DataDrivenProperty
} = require('../properties');

                                                                
                                                                           
                                                        

const lineFloorwidthProperty = new DataDrivenProperty(properties.paint.properties['line-width'].specification, true);

class LineStyleLayer extends StyleLayer {
                                            
                                           

                                                     
                                                   
                                         

    constructor(layer                    ) {
        super(layer, properties);
    }

    recalculate(parameters                      ) {
        super.recalculate(parameters);

        (this.paint._values     )['line-floorwidth'] =
            lineFloorwidthProperty.possiblyEvaluate(this._transitioningPaint._values['line-width'].value, parameters);
    }

    createBucket(parameters                  ) {
        return new LineBucket(parameters);
    }

    queryRadius(bucket        )         {
        const lineBucket             = (bucket     );
        const width = getLineWidth(
            getMaximumPaintValue('line-width', this, lineBucket),
            getMaximumPaintValue('line-gap-width', this, lineBucket));
        const offset = getMaximumPaintValue('line-offset', this, lineBucket);
        return width / 2 + Math.abs(offset) + translateDistance(this.paint.get('line-translate'));
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        )          {
        const translatedPolygon = translate(queryGeometry,
            this.paint.get('line-translate'),
            this.paint.get('line-translate-anchor'),
            bearing, pixelsToTileUnits);
        const halfWidth = pixelsToTileUnits / 2 * getLineWidth(
            this.paint.get('line-width').evaluate(feature),
            this.paint.get('line-gap-width').evaluate(feature));
        const lineOffset = this.paint.get('line-offset').evaluate(feature);
        if (lineOffset) {
            geometry = offsetLine(geometry, lineOffset * pixelsToTileUnits);
        }
        return multiPolygonIntersectsBufferedMultiLine(translatedPolygon, geometry, halfWidth);
    }
}

module.exports = LineStyleLayer;

function getLineWidth(lineWidth, lineGapWidth) {
    if (lineGapWidth > 0) {
        return lineGapWidth + 2 * lineWidth;
    } else {
        return lineWidth;
    }
}

function offsetLine(rings, offset) {
    const newRings = [];
    const zero = new Point(0, 0);
    for (let k = 0; k < rings.length; k++) {
        const ring = rings[k];
        const newRing = [];
        for (let i = 0; i < ring.length; i++) {
            const a = ring[i - 1];
            const b = ring[i];
            const c = ring[i + 1];
            const aToB = i === 0 ? zero : b.sub(a)._unit()._perp();
            const bToC = i === ring.length - 1 ? zero : c.sub(b)._unit()._perp();
            const extrude = aToB._add(bToC)._unit();

            const cosHalfAngle = extrude.x * bToC.x + extrude.y * bToC.y;
            extrude._mult(1 / cosHalfAngle);

            newRing.push(extrude._mult(offset)._add(b));
        }
        newRings.push(newRing);
    }
    return newRings;
}
