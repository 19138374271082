//      

                                                                     

const createVertexArrayType = require('./vertex_array_type');
const packUint8ToFloat = require('../shaders/encode_attribute').packUint8ToFloat;
const VertexBuffer = require('../gl/vertex_buffer');
const Color = require('../style-spec/util/color');

                                                   
                                                                                                                  
                                             
                                                                                             
                                                                                           

                        
                 
                   
                       
 

                       
                     
                  
                            
 

                                       
                                       
 

                                
                                             
                                       
                                                     
                                               
                                                 
                                            
                                        
 

function packColor(color       )                   {
    return [
        packUint8ToFloat(255 * color.r, 255 * color.g),
        packUint8ToFloat(255 * color.b, 255 * color.a)
    ];
}

/**
 *  `Binder` is the interface definition for the strategies for constructing,
 *  uploading, and binding paint property data as GLSL attributes.
 *
 *  It has three implementations, one for each of the three strategies we use:
 *
 *  * For _constant_ properties -- those whose value is a constant, or the constant
 *    result of evaluating a camera expression at a particular camera position -- we
 *    don't need a vertex buffer, and instead use a uniform.
 *  * For data expressions, we use a vertex buffer with a single attribute value,
 *    the evaluated result of the source function for the given feature.
 *  * For composite expressions, we use a vertex buffer with two attributes: min and
 *    max values covering the range of zooms at which we expect the tile to be
 *    displayed. These values are calculated by evaluating the composite expression for
 *    the given feature at strategically chosen zoom levels. In addition to this
 *    attribute data, we also use a uniform value which the shader uses to interpolate
 *    between the min and max value at the final displayed zoom level. The use of a
 *    uniform allows us to cheaply update the value on every frame.
 *
 *  Note that the shader source varies depending on whether we're using a uniform or
 *  attribute. We dynamically compile shaders at runtime to accomodate this.
 *
 * @private
 */
                     
                     
                                

                                               
                                     
                                      
                                               

                             

                                          
                                 
                                          
                                                                       
 

class ConstantBinder                         {
             
                 
                 
                     
                                

    constructor(value   , name        , type        , property        ) {
        this.value = value;
        this.name = name;
        this.type = type;
        this.property = property;
        this.statistics = { max: -Infinity };
    }

    defines() {
        return [`#define HAS_UNIFORM_u_${this.name}`];
    }

    populatePaintArray() {}

    setUniforms(gl                       ,
                program         ,
                globals                  ,
                currentValue                                   ) {
        const value      = currentValue.constantOr(this.value);
        if (this.type === 'color') {
            gl.uniform4f(program.uniforms[`u_${this.name}`], value.r, value.g, value.b, value.a);
        } else {
            gl.uniform1f(program.uniforms[`u_${this.name}`], value);
        }
    }
}

class SourceExpressionBinder                         {
                                 
                 
                 
                     
                                

    constructor(expression                  , name        , type        , property        ) {
        this.expression = expression;
        this.name = name;
        this.type = type;
        this.property = property;
        this.statistics = { max: -Infinity };
    }

    defines() {
        return [];
    }

    populatePaintArray(paintArray             ,
                       start        ,
                       length        ,
                       feature         ) {
        const value = this.expression.evaluate({zoom: 0}, feature);

        if (this.type === 'color') {
            const color = packColor(value);
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}0`] = color[0];
                struct[`a_${this.name}1`] = color[1];
            }
        } else {
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}`] = value;
            }

            this.statistics.max = Math.max(this.statistics.max, value);
        }
    }

    setUniforms(gl                       , program         ) {
        gl.uniform1f(program.uniforms[`a_${this.name}_t`], 0);
    }
}

class CompositeExpressionBinder                         {
                                    
                 
                 
                     
                            
                 
                                

    constructor(expression                     , name        , type        , property        , useIntegerZoom         , zoom        ) {
        this.expression = expression;
        this.name = name;
        this.type = type;
        this.property = property;
        this.useIntegerZoom = useIntegerZoom;
        this.zoom = zoom;
        this.statistics = { max: -Infinity };
    }

    defines() {
        return [];
    }

    populatePaintArray(paintArray             ,
                       start        ,
                       length        ,
                       feature         ) {
        const min = this.expression.evaluate({zoom: this.zoom    }, feature);
        const max = this.expression.evaluate({zoom: this.zoom + 1}, feature);

        if (this.type === 'color') {
            const minColor = packColor(min);
            const maxColor = packColor(max);
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}0`] = minColor[0];
                struct[`a_${this.name}1`] = minColor[1];
                struct[`a_${this.name}2`] = maxColor[0];
                struct[`a_${this.name}3`] = maxColor[1];
            }
        } else {
            for (let i = start; i < length; i++) {
                const struct      = paintArray.get(i);
                struct[`a_${this.name}0`] = min;
                struct[`a_${this.name}1`] = max;
            }

            this.statistics.max = Math.max(this.statistics.max, min, max);
        }
    }

    interpolationFactor(currentZoom        ) {
        if (this.useIntegerZoom) {
            return this.expression.interpolationFactor(Math.floor(currentZoom), this.zoom, this.zoom + 1);
        } else {
            return this.expression.interpolationFactor(currentZoom, this.zoom, this.zoom + 1);
        }
    }

    setUniforms(gl                       , program         , globals                  ) {
        gl.uniform1f(program.uniforms[`a_${this.name}_t`], this.interpolationFactor(globals.zoom));
    }
}

                                              
                                 
                                    
                                       
  

/**
 * ProgramConfiguration contains the logic for binding style layer properties and tile
 * layer feature data into GL program uniforms and vertex attributes.
 *
 * Non-data-driven property values are bound to shader uniforms. Data-driven property
 * values are bound to vertex attributes. In order to support a uniform GLSL syntax over
 * both, [Mapbox GL Shaders](https://github.com/mapbox/mapbox-gl-shaders) defines a `#pragma`
 * abstraction, which ProgramConfiguration is responsible for implementing. At runtime,
 * it examines the attributes of a particular layer, combines this with fixed knowledge
 * about how layers of the particular type are implemented, and determines which uniforms
 * and vertex attributes will be required. It can then substitute the appropriate text
 * into the shader source code, create and link a program, and bind the uniforms and
 * vertex attributes in preparation for drawing.
 *
 * When a vector tile is parsed, this same configuration information is used to
 * populate the attribute buffers needed for data-driven styling using the zoom
 * level and feature property data.
 *
 * @private
 */
class ProgramConfiguration {
                                       
                     
                                 
                                         

                      
                                  
                                                     
                                     

    constructor() {
        this.binders = {};
        this.cacheKey = '';
    }

    static createDynamic(programInterface                  , layer            , zoom        ) {
        const self = new ProgramConfiguration();
        const attributes = [];

        for (const attribute of programInterface.paintAttributes || []) {
            const property = attribute.property;
            const name = attribute.name || property.replace(`${layer.type}-`, '').replace(/-/g, '_');
            const value                                      = (layer.paint     ).get(property);
            const type = value.property.specification.type;
            const useIntegerZoom = value.property.useIntegerZoom;

            if (value.value.kind === 'constant') {
                self.binders[name] = new ConstantBinder(value.value, name, type, property);
                self.cacheKey += `/u_${name}`;
            } else if (value.value.kind === 'source') {
                self.binders[name] = new SourceExpressionBinder(value.value, name, type, property);
                self.cacheKey += `/a_${name}`;
                attributes.push({
                    name: `a_${name}`,
                    type: 'Float32',
                    components: type === 'color' ? 2 : 1
                });
            } else {
                self.binders[name] = new CompositeExpressionBinder(value.value, name, type, property, useIntegerZoom, zoom);
                self.cacheKey += `/z_${name}`;
                attributes.push({
                    name: `a_${name}`,
                    type: 'Float32',
                    components: type === 'color' ? 4 : 2
                });
            }
        }

        self.PaintVertexArray = createVertexArrayType(attributes);
        self.interface = programInterface;
        self.layer = layer;

        return self;
    }

    static forBackgroundColor(color       , opacity        ) {
        const self = new ProgramConfiguration();

        self.binders.color = new ConstantBinder(color, 'color', 'color', 'background-color');
        self.cacheKey += `/u_color`;

        self.binders.opacity = new ConstantBinder(opacity, 'opacity', 'number', 'background-opacity');
        self.cacheKey += `/u_opacity`;

        return self;
    }

    static forBackgroundPattern(opacity        ) {
        const self = new ProgramConfiguration();

        self.binders.opacity = new ConstantBinder(opacity, 'opacity', 'number', 'background-opacity');
        self.cacheKey += `/u_opacity`;

        return self;
    }

    static forTileClippingMask() {
        // The color and opacity values don't matter.
        return ProgramConfiguration.forBackgroundColor(Color.black, 1);
    }

    populatePaintArray(length        , feature         ) {
        const paintArray = this.paintVertexArray;
        if (paintArray.bytesPerElement === 0) return;

        const start = paintArray.length;
        paintArray.resize(length);

        for (const name in this.binders) {
            this.binders[name].populatePaintArray(
                paintArray,
                start, length,
                feature);
        }
    }

    defines()                {
        const result = [];
        for (const name in this.binders) {
            result.push.apply(result, this.binders[name].defines());
        }
        return result;
    }

    setUniforms                    (gl                       , program         , properties                               , globals                  ) {
        for (const name in this.binders) {
            const binder = this.binders[name];
            binder.setUniforms(gl, program, globals, properties.get(binder.property));
        }
    }

    serialize(transferables                      )                                  {
        if (this.paintVertexArray.length === 0) {
            return null;
        }

        const statistics                          = {};
        for (const name in this.binders) {
            statistics[this.binders[name].property] = this.binders[name].statistics;
        }

        return {
            array: this.paintVertexArray.serialize(transferables),
            type: this.paintVertexArray.constructor.serialize(),
            statistics
        };
    }

    static deserialize(programInterface                  , layer            , zoom        , serialized                                 ) {
        const self = ProgramConfiguration.createDynamic(programInterface, layer, zoom);
        if (serialized) {
            self.PaintVertexArray = createVertexArrayType(serialized.type.members);
            self.paintVertexArray = new self.PaintVertexArray(serialized.array);
            self.paintPropertyStatistics = serialized.statistics;
        }
        return self;
    }

    upload(gl                       ) {
        if (this.paintVertexArray) {
            this.paintVertexBuffer = new VertexBuffer(gl, this.paintVertexArray);
        }
    }

    destroy() {
        if (this.paintVertexBuffer) {
            this.paintVertexBuffer.destroy();
        }
    }
}

class ProgramConfigurationSet {
                                                            

    constructor(programInterface                  , layers                            , zoom        , arrays                                               ) {
        this.programConfigurations = {};
        if (arrays) {
            for (const layer of layers) {
                this.programConfigurations[layer.id] = ProgramConfiguration.deserialize(programInterface, layer, zoom, arrays[layer.id]);
            }
        } else {
            for (const layer of layers) {
                const programConfiguration = ProgramConfiguration.createDynamic(programInterface, layer, zoom);
                programConfiguration.paintVertexArray = new programConfiguration.PaintVertexArray();
                this.programConfigurations[layer.id] = programConfiguration;
            }
        }
    }

    populatePaintArrays(length        , feature         ) {
        for (const key in this.programConfigurations) {
            this.programConfigurations[key].populatePaintArray(length, feature);
        }
    }

    serialize(transferables                      ) {
        const result = {};
        for (const layerId in this.programConfigurations) {
            const serialized = this.programConfigurations[layerId].serialize(transferables);
            if (!serialized) continue;
            result[layerId] = serialized;
        }
        return result;
    }

    get(layerId        ) {
        return this.programConfigurations[layerId];
    }

    upload(gl                       ) {
        for (const layerId in this.programConfigurations) {
            this.programConfigurations[layerId].upload(gl);
        }
    }

    destroy() {
        for (const layerId in this.programConfigurations) {
            this.programConfigurations[layerId].destroy();
        }
    }
}

module.exports = {
    ProgramConfiguration,
    ProgramConfigurationSet
};
