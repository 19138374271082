//      

const StyleLayer = require('../style_layer');
const CircleBucket = require('../../data/bucket/circle_bucket');
const {multiPolygonIntersectsBufferedMultiPoint} = require('../../util/intersection_tests');
const {getMaximumPaintValue, translateDistance, translate} = require('../query_utils');
const properties = require('./circle_style_layer_properties');

const {
    Transitionable,
    Transitioning,
    PossiblyEvaluated
} = require('../properties');

                                                                
                                                
                                                                

class CircleStyleLayer extends StyleLayer {
                                                     
                                                   
                                         

    constructor(layer                    ) {
        super(layer, properties);
    }

    createBucket(parameters                  ) {
        return new CircleBucket(parameters);
    }

    queryRadius(bucket        )         {
        const circleBucket               = (bucket     );
        return getMaximumPaintValue('circle-radius', this, circleBucket) +
            getMaximumPaintValue('circle-stroke-width', this, circleBucket) +
            translateDistance(this.paint.get('circle-translate'));
    }

    queryIntersectsFeature(queryGeometry                     ,
                           feature                   ,
                           geometry                     ,
                           zoom        ,
                           bearing        ,
                           pixelsToTileUnits        )          {
        const translatedPolygon = translate(queryGeometry,
            this.paint.get('circle-translate'),
            this.paint.get('circle-translate-anchor'),
            bearing, pixelsToTileUnits);
        const radius = this.paint.get('circle-radius').evaluate(feature) * pixelsToTileUnits;
        const stroke = this.paint.get('circle-stroke-width').evaluate(feature) * pixelsToTileUnits;
        return multiPolygonIntersectsBufferedMultiPoint(translatedPolygon, geometry, radius + stroke);
    }
}

module.exports = CircleStyleLayer;
